<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
    <b-overlay :show="loading">
      <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
        <b-row>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Type" vid="type_id" rules="required|min_value:1">
              <b-form-group
                label-for="type_id"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{ $t('globalTrans.type') }}<span class="text-danger">*</span>
              </template>
              <b-form-select
                plain
                v-model="formData.type_id"
                :options="typeList"
                id="type_id"
                :state="errors[0] ? false : (valid ? true : null)"
              >
                <template v-slot:first>
                  <b-form-select-option :value=0>{{ userLoading ? 'Loading..' : $t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Comment (En)" vid="comment_en" rules="required" v-slot="{ errors }">
              <b-form-group
                label-for="comment_en">
                <template v-slot:label>
                  {{ $t('tradeTariffConfig.comment') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="comment_en"
                  v-model="formData.comment_en"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Comment (Bn)" vid="comment_bn" rules="required" v-slot="{ errors }">
              <b-form-group
                label-for="comment_bn">
                <template v-slot:label>
                  {{ $t('tradeTariffConfig.comment') }} {{ $t('globalTrans.bn') }} <span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="comment_bn"
                  v-model="formData.comment_bn"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
        <b-row class="text-right">
          <b-col>
              <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
              <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-form')">{{ $t('globalTrans.cancel') }}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </ValidationObserver>
</template>
<script>
import RestApi, { tradeTariffServiceBaseUrl } from '@/config/api_config'
import { commentStore, commentUpdate } from '../../api/routes'
export default {
  name: 'Form',
  props: ['id'],
  data () {
    return {
      valid: null,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      errors: [],
      formData: {
        type_id: 0,
        comment_en: '',
        comment_bn: ''
      }
    }
  },
  created () {
    if (this.id) {
      const tmp = this.getApplicationType()
      this.formData = tmp
    }
  },
  computed: {
    loading: function () {
      return this.$store.state.commonObj.loading
    },
    typeList () {
      let typeList = [
        {
          value: '1',
          text_en: 'Forward',
          text_bn: 'ফরোয়ার্ড'
        },
        {
            value: '2',
            text_en: 'Assign',
            text_bn: 'বরাদ্দ'
        },
        {
            value: '3',
            text_en: 'Approve',
            text_bn: 'অনুমোদন'
        },
        {
            value: '4',
            text_en: 'Archive',
            text_bn: 'আর্কাইভ'
        }
      ]
       typeList = typeList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
      return typeList
    }
  },
  methods: {
    async saveUpdate () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }
      if (this.id) {
        result = await RestApi.putData(tradeTariffServiceBaseUrl, `${commentUpdate}/${this.id}`, this.formData)
      } else {
        result = await RestApi.postData(tradeTariffServiceBaseUrl, commentStore, this.formData)
      }

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.dispatch('TraeTariffService/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-form')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    getApplicationType () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    }
  }
}
</script>
